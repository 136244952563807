.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.nav {
  font-weight: 900;
  font-size: 18px;
  color: white;
  text-decoration: none;
  margin-left: 30px;
}

.nav:hover {
  transform: scale(1.1);
}

.nav:active {
  transform: scale(1.1);
  color: white;
}

.logout {
  display: flex;
  width: 300px;
}

.chartBox2 {
  background: linear-gradient(90.4deg, #74d5ab 0.21%, #abd7c4 99.73%);
  padding: 50px;
  h1 {
    font-size: 40px;
    font-weight: bold;
    margin: 20px 0;
  }
  h3 {
    font-size: 20px;
    margin: 20px 0;
  }
}

.chartBox1 {
  padding: 50px;
  h1 {
    font-size: 40px;
    font-weight: bold;
    margin: 20px 0;
  }
  h3 {
    font-size: 20px;
    margin: 20px 0;
  }
}
.chartGraph {
  margin: 0 auto;
  // box-shadow: 5px 5px;

}

.graph {
  margin: 0 auto;
}

.bottomWord {
  h1 {
    font-size: 40px;
    font-weight: bold;
  }
  h3 {
    font-size: 20px;
  }
}
